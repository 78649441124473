import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Contact = () => {
  const [FormState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [SubmitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = (e) => {
    setFormState({
      ...FormState,
      [e.target.name]: e.target.value,
    });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    setSubmitDisabled(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "General Contact", ...FormState }),
    })
      .then(() => window.open("../thank-you-general", "_self"))
      .catch(() => window.open("../Error", "_self"));

    e.preventDefault();
  };
  return (
    <div>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title>United Green Energy Credits | Contact Us</title>

        <meta
          name="description"
          content="United Green Energy can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green with UGE! "
        />
        <link
          rel="icon"
          href="https://imgix.cosmicjs.com/5ffde510-a9f1-11ec-bd4d-d755cb3d1844-UGE-Icon-Green-01.png"
        />
      </Helmet>
      <Header />{" "}
      <div class="mt-8 relative bg-white mx-auto text-center ">
        {" "}
        <p class="relative inline-flex items-center px-5 py-1 rounded-md text-base font-medium bg-emerald-100 text-emerald-600">
          CONTACT
        </p>
        <h1 class="mt-4 relative max-w-6xl mx-auto text-4xl font-bold text-emerald-600">
          Send Us A Note
        </h1>
        <p class="px-10 md:px-0  max-w-lg mx-auto text-base text-gray-600">
          Curious about how to go green with UGEC? Contact us today!
        </p>
      </div>
      <div class=" mb-20 items-center relative md:flex md:justify-center max-w-6xl mx-auto">
        <div class="desktop contact hidden md:block px-5 md:px-0 md:w-1/3">
          {" "}
          <div class="flex inline items-center relative rounded-lg border border-white bg-emerald-700 px-6 py-4 shadow shadow-2xl ">
            <svg
              class="inline mr-8 ml-2 mb-1 h-14"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
            >
              <path d="M1 22h2v-22h18v22h2v2h-22v-2zm7-3v4h3v-4h-3zm5 0v4h3v-4h-3zm-6-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z" />
            </svg>{" "}
            <div class="text-left">
              <span class="" aria-hidden="true"></span>
              <h2 class="text-xl font-bold text-white">Visit Us</h2>
              <p class=" text-sm text-white">
                225 Union Blvd, Suite 200, <br></br>Lakewood, CO 80228
              </p>
            </div>
          </div>
          <h2 class="pointer-events-none mt-10 flex inline items-center relative rounded-lg bg-emerald-100 px-6 py-7 shadow shadow-2xl ">
            <svg
              class="inline mr-8 ml-2 mb-1 h-14 text-emerald-700"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
            </svg>{" "}
            <div class="text-left">
              <span class="" aria-hidden="true"></span>
              <h2 class="text-xl font-bold text-emerald-600">Email:</h2>
              <p class=" text-sm text-gray-500">UnitedGreenEnergy@uetllc.com</p>
            </div>
          </h2>
          <h2 class="mt-10 pointer-events-none flex inline items-center relative rounded-lg bg-white px-6 py-7 shadow shadow-2xl">
            <svg
              class="inline mr-8 ml-2 mb-1 h-14 text-emerald-700"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z" />
            </svg>{" "}
            <div class="text-left">
              <span class="" aria-hidden="true"></span>
              <h2 class="text-xl font-bold text-emerald-600">Phone:</h2>
              <p class=" text-sm font-medium text-gray-500">303-991-0983</p>
            </div>
          </h2>
        </div>

        <div class=" px-5 md:px-0  md:ml-20 md:w-1/2">
          {" "}
          <div class="py-10 mx-auto">
            <form
              method="POST"
              target="_self"
              id="General Contact"
              name="General Contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              class="grid grid-cols-1 gap-y-6"
            >
              <div>
                <label for="full_name" class="sr-only">
                  Full name
                </label>
                <div class="relative">
                  <input
                    id="full_name"
                    name="name"
                    type="text"
                    onChange={handleChange}
                    class="border form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    placeholder="Full name"
                    required
                  ></input>
                </div>
              </div>
              <div>
                <label for="email" class="sr-only">
                  Email
                </label>
                <div class="relative">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    class="border form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    placeholder="Email"
                    required
                  ></input>
                </div>
              </div>
              <div>
                <label for="phone" class="sr-only">
                  Phone
                </label>
                <div class="relative">
                  <input
                    id="phone"
                    name="phone"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={handleChange}
                    class="border form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    placeholder="Phone"
                    required
                  ></input>
                </div>
              </div>
              <div>
                <label for="message" class="sr-only">
                  Message
                </label>
                <div class="relative">
                  <textarea
                    id="message"
                    name="message"
                    onChange={handleChange}
                    rows="4"
                    class="border form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    placeholder="Message"
                  ></textarea>
                </div>
              </div>

              {SubmitDisabled === true ? (
                <div class="">
                  <button
                    type="submit"
                    class="w-1/2 py-3 text-base font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-neonGreen"
                  >
                    Loading...
                  </button>
                </div>
              ) : (
                <div class="">
                  <button
                    type="submit"
                    class="w-1/2 py-3 text-base font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-700 focus:outline-none focus:ring-2  focus:ring-neonGreen"
                  >
                    Submit
                  </button>
                </div>
              )}
            </form>
          </div>
          <div class="mobile contact md:hidden px-5 md:px-0 md:w-1/3">
            {" "}
            <div class="flex inline items-center relative rounded-lg border border-white bg-emerald-700 px-6 py-4 shadow shadow-2xl ">
              <svg
                class="inline mr-8 ml-2 mb-1 h-14"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
              >
                <path d="M1 22h2v-22h18v22h2v2h-22v-2zm7-3v4h3v-4h-3zm5 0v4h3v-4h-3zm-6-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z" />
              </svg>{" "}
              <div class="text-left">
                <span class="" aria-hidden="true"></span>
                <h2 class="pt-4 text-xl font-bold text-white">Visit Us</h2>
                <p class=" text-sm text-white">
                  225 Union Blvd, Suite 200, <br></br>Lakewood, CO 80228
                </p>
              </div>
            </div>
            <a
              class=" mt-10 flex inline items-center relative rounded-lg bg-emerald-100 px-6 py-7 shadow shadow-2xl "
              href="mailto:UnitedGreenEnergy@uetllc.com"
            >
              <svg
                class="inline mr-8 ml-2 mb-1 h-14 text-emerald-700"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
              </svg>{" "}
              <div class="text-left">
                <span class="" aria-hidden="true"></span>
                <h2 class="pt-4 text-xl font-bold text-emerald-600">Email:</h2>
                <p class=" text-sm text-gray-500">
                  UnitedGreenEnergy@uetllc.com
                </p>
              </div>
            </a>
            <a
              class="mt-10 flex inline items-center relative rounded-lg bg-white px-6 py-7 shadow shadow-2xl"
              href="tel:303-991-0983"
            >
              <svg
                class="inline mr-8 ml-2 mb-1 h-14 text-emerald-700"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z" />
              </svg>{" "}
              <div class="text-left">
                <span class="" aria-hidden="true"></span>
                <h2 class="pt-4 text-xl font-bold text-emerald-600">Phone:</h2>
                <p class=" text-sm text-gray-500">303-991-0983</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Contact;
